import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Portal() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic client-side validation
    if (!username || !password) {
      setError('Both fields are required');
      return;
    }

    try {
      const response = await fetch('https://portal.lsb.go.ke/admin/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include'
      });

      if (response.ok) {
        // Redirect to the dashboard if login is successful
        navigate('/dashboard');
      } else {
        // If the login fails, display an error message
        const data = await response.json();
        setError(data.message || 'Login failed');
      }
    } catch (err) {
      setError('Error during login: ' + err.message);
    }
  };

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-lg">
        <h1 className="text-center font-bold">Surveyor's Portal</h1>

        <form
          onSubmit={handleSubmit}
          className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
        >
          {error && <p className="text-red-500">{error}</p>}

          <div>
            <label htmlFor="username" className="sr-only">ID Number</label>
            <div className="relative">
              <input
                type="number"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full rounded-lg border-gray-200 p-4 pe-12 shadow-sm"
                placeholder="ID Number"
                required
              />
              <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                <i className="fa-solid fa-envelope"></i>
              </span>
            </div>
          </div>

          <div>
            <label htmlFor="password" className="sr-only">Password</label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                placeholder="Password"
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 end-0 grid place-content-center px-4 cursor-pointer"
              >
                {showPassword ? (
                  <i className="fa-regular fa-eye"></i>
                ) : (
                  <i className="fa-regular fa-eye-slash"></i>
                )}
              </button>
            </div>
          </div>

          <button type="submit" className="button">
            <span className="button_lg">
              <span className="button_sl"></span>
              <span className="button_text">Sign In</span>
            </span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default Portal;