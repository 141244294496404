import React from "react";
import "./Footer.css";
import LsbLogo from "../Assets/lsbLogo.png";
import Facebook from "./facebook.png";
import Twitter from "./Xtwitter.png";
import LinkedIn from "./linkedin.png";
import { NavLink } from "react-router-dom";

function Footer() {
  const CurrentYear = () => {
    const year = new Date().getFullYear();
    return <span>{year}</span>;
  };

  return (
    <div className="bg-gray-600">
      <footer className="footer-section bg-black">
        <div className="container">
          {/* contact details */}
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 border-b pt-3">
            <div className="flex flex-col gap-2 justify-center items-center text-center cursor-pointer">
              <i className="fas fa-phone text-blue-300 fa-2x"></i>
              <div>
                <p className="text-white fw-bold">Call us</p>
                <small className="text-white">+254 799 093 219</small>
              </div>
            </div>

            <div className="flex flex-col gap-2 justify-center items-center text-center cursor-pointer">
              <NavLink exact to="/contact"><i className="fas fa-map-marker-alt  text-blue-300  fa-2x"></i></NavLink>
              <div>
                <p className="text-white fw-bold">Find us</p>
                <small className="text-white">
                  Land Surveyors Board <br /> Survey of Kenya Headquarters <br /> P.O. Box 30046-00100, Ruaraka Nairobi- Kenya
                </small>
              </div>
            </div>

            <div className="flex flex-col gap-2 justify-center items-center text-center cursor-pointer">
              <i className="far fa-envelope  text-blue-300 fa-2x"></i>
              <div>
                <p className="text-white fw-bold">Mail us</p>
                <small className="text-white">info@lsb.go.ke</small>
              </div>
            </div>
          </div>

          {/* footer summary */}
          <div className="footer-content py-5">
            <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-8">
              <div className="flex flex-col justify-center">
                <div className="footer-widget">
                  <div className="flex justify-center items-center my-3">
                    <a href="/">
                      <img src={LsbLogo} className="img-fluid" alt="logo" />
                    </a>
                  </div>

                  <div className="text-center mx-4">
                    <p className="text-gray-300">
                      The Land Surveyors Board was founded in 1923 and operates
                      under the authority of the Survey Act Cap 299 of the Laws
                      of Kenya. We regulate land surveyors' practice to ensure
                      professionalism and uphold industry standards within the
                      Republic of Kenya.
                    </p>
                  </div>

                  <div className="flex justify-center items-center gap-3 mx-3">
                    <span className="text-white">Follow us</span>
                    <a href="https://www.facebook.com/profile.php?id=61558428002052" target="_blank" rel="noreferrer" className="text-white">
                      <img alt="linkedIn" src={Facebook} />
                    </a>
                    <a href="https://twitter.com/kenya_lsb" target="_blank" rel="noreferrer" className="text-white">
                      <img alt="linkedIn" src={Twitter} />
                    </a>
                    <a href="https://www.linkedin.com/company/land-surveyors-board-ken" target="_blank" rel="noreferrer" className="text-white">
                      <img alt="linkedIn" src={LinkedIn} />
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="footer-widget">
                  <h5 className="text-white fw-bold">Useful Links</h5>

                  <div className="flex flex-col">
                    <a
                      href="https://ardhisasa.lands.go.ke/home"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      Ardhisasa
                    </a>

                    <a
                      href="https://lands.go.ke/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      State Department for Lands & Physical Planning
                    </a>

                    <a
                      href="https://isk.or.ke/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      Institution of Surveyors of Kenya
                    </a>

                    <a
                      href="https://www.allsk.co.ke/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      Association of Licensed Land Surveyors of Kenya (ALLSK)
                    </a>

                    <a
                      href="https://kism.ac.ke/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      Kenya Institute of Surveying & Mapping
                    </a>

                    <a
                      href="https://accounts.ecitizen.go.ke/en"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      E-CITIZEN
                    </a>

                    <a
                      href="https://landcommission.go.ke/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-400 text-decoration-none hover:text-white"
                    >
                      National Land Commission
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h5 className="text-white fw-bold">Subscribe</h5>
                  </div>

                  <div className="footer-text mb-25">
                    <p className="text-gray-300">
                      Don&apos;t miss to subscribe to our new feeds.
                    </p>
                  </div>

                  <div className="">
                    <form action="/" className="flex">
                      <input
                        type="text"
                        placeholder="Email Address"
                        className="bg-gray-400 p-2 rounded-l-lg"
                      />
                      <button className="rounded-r-lg bg-blue-600 px-4">
                        <i class="fa-solid fa-envelope text-white"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* footer navigation */}
      <div className="flex flex-col justify-center items-center py-3">
        <div className="flex gap-3">
          <small><a href="/" className="text-white text-decoration-none">Home</a></small>
          <small><a href="/about" className="text-white text-decoration-none">About</a></small>
          <small><a href="/register" className="text-white text-decoration-none">Register</a></small>
          <small><a href="/resources" className="text-white text-decoration-none">Resources</a></small>
          <small><a href="/contact" className="text-white text-decoration-none">Contact</a></small>
          <small><a href="/privacy" className="text-white text-decoration-none">Privacy</a></small>
        </div>

        <div className="py-2">
          <small className="text-white">
            Copyright &copy; <CurrentYear/>{" "}
            <a href="/" className="text-decoration-none text-white fw-bold">
              SoK Devs
            </a>
          </small>
        </div>
      </div>
    </div>
  );
}

export default Footer;
