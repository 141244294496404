import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const backendUrl = "https://portal.lsb.go.ke/account/licensed/";

function PublicProfile() {
  const { licenseNumber } = useParams(); // Getting the license number from the URL
  const [surveyor, setSurveyor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchSurveyor() {
      const url = `${backendUrl}/${licenseNumber}`; // Assuming the API supports fetching by license number
      console.log(`Fetching surveyor with license number: ${licenseNumber} from URL: ${url}`); // Debug log

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch surveyor details: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        console.log('Fetched data:', data); // Logging the fetched data for verification
        setSurveyor(data);
      } catch (error) {
        console.error('Error fetching surveyor details:', error);
        setError('Failed to fetch surveyor details');
      } finally {
        setLoading(false);
      }
    }

    fetchSurveyor();
  }, [licenseNumber]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {surveyor ? (
        <div>
          <h1>{surveyor.name}</h1>
          <p>License Number: {surveyor.license_no}</p>
          <p>Organization: {surveyor.firm}</p>
          <p>Address: {surveyor.postal_address}</p>
          <p>Status: {surveyor.status}</p>
          {/* Add other surveyor details as needed */}
        </div>
      ) : (
        <div>No details found for this surveyor.</div>
      )}
    </div>
  );
}

export default PublicProfile;