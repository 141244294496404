import React from 'react';
import Circular2of2024 from './Notices/Land Law Examination Notice 2024 Circular No 2 of 2024.pdf';

const pdfs = [
    { name: "Land Law Notice Circular 2 - 2024", file: Circular2of2024 },
];

const Notices = () => {
    return (
        <div className="">
            <details className="group [&_summary::-webkit-details-marker]:hidden" closed>
                <summary
                    className="flex cursor-pointer items-center justify-between gap-1.5 rounded-b-lg bg-gray-300 p-2 text-gray-900"
                >
                    <p className="">Notices</p>

                    <svg
                        className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </summary>

                <div className="py-2 flex gap-2 flex-wrap ">
                    {pdfs.map(({ name, file }) => (
                        <div key={name} className="mb-2">
                            <a
                                href={file}
                                rel="noreferrer"
                                target="_blank"
                                className="text-decoration-none text-black bg-gray-400 px-2 py-1 hover:font-bold rounded-lg"
                            >
                                {name}
                            </a>
                        </div>
                    ))}
                </div>
            </details>
        </div>
    )
}

export default Notices;