import React from "react";
import "./Home.css";
import Tick from "./images/tick.svg";
import NoticeCard from "./noticeCard";
import Divider from "../divider";
import NewsCarousel from "./newsCarousel";
import HeroCarousel from "./heroCarousel";

// socials
import Facebook from "./images/facebook.png";
import LinkedIn from "./images/linkedin.png";
import Twitter from "./images/Xtwitter.png";
import FacebookWidget from "./FacebookWidget";
// socials

function DutyItem({ text }) {
  return (
    <div className="flex gap-2">
      <img src={Tick} alt="checked2" className="tick-icon" style={{ width: "30px", height: "30px" }} />
      <p>{text}</p>
    </div>
  );
}

function Home() {
  return (
    <div className="mb-3">
      <HeroCarousel />

      <div className="container mb-3">
        <div className="flex flex-col justify-center items-center text-center my-16">
          <h1 className="flex flex-col font-black leading-none text-gray-800 dark:text-white uppercase mb-3">
            land surveyors' board
          </h1>
          <p className="">
            The Land Surveyors&apos; Board is a regulatory board established under
            Section 7 of the Survey Act Cap 299 of the Laws of Kenya. It plays a
            pivotal role in ensuring the integrity, professionalism, and
            competence of land surveyors across the country.
          </p>

          <h5 className="fw-bold mt-4 text-red-800">
            Our Mission:
          </h5>
          <p className="">
            Our mission is to uphold the highest standards of land surveying
            practice, promote ethical conduct, and safeguard the public interest
            in matters related to land surveying.
          </p>

          <h5 className="fw-bold mt-4 text-red-800">
            Our Vision:
          </h5>
          <p className="mb-5">
            To be a leading regulatory board that inspires confidence, trust,
            and excellence in the land surveying profession.
          </p>
        </div>

        <div className="mt-3">
          <Divider title="DUTIES OF THE BOARD" />

          <div className="grid grid-cols-1 gap-2 lg:grid-cols-3 lg:gap-8 mt-3">
            <DutyItem
              text="To conduct the examination of candidates for admission as licensed surveyors in accordance with the provisions of this Act"
            />

            <DutyItem
              text="To grant licenses and keep register of all licensed surveyors in accordance with the provision of the Survey Act"
            />

            <DutyItem
              text="To take disciplinary proceedings against licensed surveyors in accordance with the provisions of this Act"
            />

            <DutyItem
              text="To hear and determine any dispute between any licensed surveyor and his client as to the fees charged by the licensed surveyor."
            />

            <DutyItem
              text="To hear and determine any dispute between the Director and a licensed surveyor as to the application of any regulations in which provision is made for reference to the Board."
            />

            <DutyItem
              text="To advise the Director on all matters relating to cadastral surveys in connection with the registration of land or of title to land under any written law for the time being in force relating to the registration of land or of title to land."
            />
          </div>
        </div>

        {/* news */}
        <div className="mt-5">
          <h2 className="font-black leading-none uppercase text-red-800">
            latest news
          </h2>

          <div className="flex gap-3">
            <a href="https://www.facebook.com/profile.php?id=61558428002052" target="_blank" rel="noreferrer" className="text-white">
              <img alt="linkedIn" src={Facebook} />
            </a>

            <a href="https://twitter.com/kenya_lsb" target="_blank" rel="noreferrer" className="text-white">
              <img alt="linkedIn" src={Twitter} />
            </a>

            <a href="https://www.linkedin.com/company/land-surveyors-board-ken" target="_blank" rel="noreferrer" className="text-white">
              <img alt="linkedIn" src={LinkedIn} />
            </a>
          </div>

          <div className="grid grid-cols-1 gap-1 lg:grid-cols-2 lg:gap-2">
            <div className="flex justify-center">
              <NewsCarousel />
            </div>

            <div className="flex justify-center">
              <FacebookWidget />
            </div>
          </div>
        </div>

        <div className="mt-20">
          <h1 className="text-uppercase text-6xl font-black leading-none text-red-800 uppercase sm:text-4xl">
            notices
          </h1>
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-3 lg:gap-8">
            <div className="h-auto">
              <NoticeCard
                imageSource="https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2426&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                title="Reports"
                pageLink="/resources"
              />
            </div>

            <div className="h-auto">
              <NoticeCard
                imageSource="https://images.unsplash.com/photo-1600492515568-8868f609511e?q=80&w=2500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                title="Guidelines"
                pageLink="/resources"
              />
            </div>

            <div className="h-auto">
              <NoticeCard
                imageSource="https://images.unsplash.com/photo-1423592707957-3b212afa6733?q=80&w=2560&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                title="Legislations"
                pageLink="/resources"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
