import "./resources.css";
import ResourcesPic from "./resources.jpg";
import BlogCard from "../blogCard";

// import pdf
import Dropdown from "./Dropdown";
import Guidelines from "./Guidelines";
import Legislations from "./Legislations";
import Notices from "./Notices";

function Resources() {
  return (
    <div className="mb-3">
      <div className="hero">
        <img
          className="h-96 w-full object-cover object-center"
          src={ResourcesPic}
          alt="Nature Scene"
        />
      </div>

      <div className="mx-8 my-8">
        <h1 className="flex flex-col font-black leading-none text-gray-800 uppercase dark:text-white text-center">
          Resources
        </h1>

        <p className="text-center">
          Get access to all the publicly declared publications and print
          material produced by the LSB. These include, but not limited to: past
          exam papers, forms, various publications, and a host of other useful
          information as well as providing links to relevant legislation.
        </p>

        <div class="grid grid-cols-1 gap-3 lg:grid-cols-3 lg:gap-8">
          {/* exam past papers */}
          <div class="resource-card">
            <div className="mt-4">
              <BlogCard
                resourceCategory="exam past papers"
                startYear="1990"
                endYear="2023"
                coverageContent="All board land law examinations for new and practising professionals."
                picBackground={require("./exams.jpg")}
              />
            </div>

            <div className="mt-1">
              <Dropdown />
            </div>
          </div>

          {/* publications */}
          <div class="resource-card">
            <div className="mt-4">
              <BlogCard
                resourceCategory="Membership Procedures"
                startYear="2010"
                endYear="2023"
                coverageContent="Access all publications printed under the LSB Kenya."
                picBackground={require("./publications.jpg")}
              />
            </div>

            <div className="mt-1">
              <Guidelines />
            </div>
          </div>

          {/* useful links */}
          <div class="resource-card">
            <div className="mt-4">
              <BlogCard
                resourceCategory="useful legislation links"
                startYear="1994"
                endYear="Current"
                coverageContent="List of some of the documentations for a surveyor or a an interested party."
                picBackground={require("./library.jpg")}
              />
            </div>

            <div className="mt-1 space-y-2">
              <Legislations />
              <Notices />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resources;
