import TopgraphicalSurvey from './Guidelines/Topographical Survey Guidelines.pdf';
import LicenseApplication from './Guidelines/Guideline to License Application.pdf';

const Guidelines = () => {
    return (
        <div className="">
            <details className="group [&_summary::-webkit-details-marker]:hidden" closed>
                <summary
                    className="flex cursor-pointer items-center justify-between gap-1.5 rounded-b-lg bg-gray-300 p-2 text-gray-900"
                >
                    <p className="">Guidelines</p>

                    <svg
                        className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </summary>

                <div className="py-2 flex gap-2 flex-wrap ">
                    <a href={TopgraphicalSurvey} rel='noreferrer' target='_blank' className="text-decoration-none text-black bg-gray-400 px-2 py-1 hover:font-bold rounded-lg">Topographical Survey</a>

                    <a href={LicenseApplication} rel='noreferrer' target='_blank' className="text-decoration-none text-black bg-gray-400 px-2 py-1 hover:font-bold rounded-lg">Guidelines to License Application</a>
                </div>
            </details>
        </div>
    );
}

export default Guidelines;