import React from "react";
import "./register.css";
import registerPic from "./register.jpg";
import Tab from "./Tabs";
import CurrentRegister from "./LicensedSurveyors";
import ApprovedSurv from "./ApprovedSurveyors";
import { Link } from "react-router-dom";

function Register() {
  const tabs = [
    {
      id: 1,
      label: "Licensed Surveyors",
      content: <CurrentRegister />,
    },
    {
      id: 2,
      label: "Approved Assistants",
      content: <ApprovedSurv />,
    },
  ];

  return (
    <div>
      <div>
        <div className="landing">
          <img
            className="h-96 w-full object-cover object-center"
            src={registerPic}
            alt="Register"
          />
        </div>

        {/* tabs */}
        <div className="mx-5 my-12">
          <h1 className="flex flex-col text-x5l font-black leading-none text-gray-800 uppercase font-bebas-neue sm:text-4xl dark:text-white">
            current register
          </h1>

          {/* <Link to="/dashboard">Dashboard</Link> */}

          <p>
            <span className="text-xl fw-bold">Licensed Surveyor</span> means a
            surveyor duly licensed as a surveyor under or by virtue of the
            provisions of the Survey Act Cap 299.
          </p>

          <p>
            <span className="text-xl fw-bold">Approved Assistant</span> means
            any person other than a licensed surveyor who assists a licensed
            surveyor in the execution of field surveys, but does not include
            skilled and semi-skilled chainmen and laborers. As per the Survey
            Regulations of 1994, No licensed surveyor shall employ an approved
            assistant without the written approval of the Board.
          </p>

          <div className="flex justify-center my-4">
            <Tab tabs={tabs} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
